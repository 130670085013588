import React, { useState } from "react";
import { Menu, X } from "lucide-react";

const NavBar = () => {
  const [nav, setNav] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredItems, setHoveredItems] = useState(null);
  const payTmLogoUrl =
    "https://pwebassets.paytm.com/commonwebassets/paytmweb/header/images/logo.svg";
  const signInLogo =
    "https://pwebassets.paytm.com/commonwebassets/paytmweb/header/images/loginImg.svg";
  const signInLogoHovered =
    "https://pwebassets.paytm.com/commonwebassets/paytmweb/header/images/logoutImg.svg";
  const navItemList = [
    {
      id: 1,
      name: "Paytm for Consumer",
      subMenu: ["Payments", "Ticket booking", "Financial Services"],
    },
    { id: 2, name: "Paytm For Business", subMenu: [] },
    { id: 3, name: "Investor Relations", subMenu: [] },
    { id: 4, name: "Company", subMenu: [] },
    { id: 5, name: "Career", subMenu: [] },
  ];

  const hamburgerMenuList = [
    { id: 1, name: "Investor Relations" },
    { id: 2, name: "Company" },
    { id: 3, name: "Career" },
  ];

  console.log(hoveredItems);
  return (
    <div className="flex  justify-center items-center py-5 shadow-lg">
      {/* mobile view Hamburger */}

      <div
        className="flex md:hidden"
        onClick={() => {
          setNav(!nav);
        }}
      >
        {!nav ? <Menu /> : <X />}
        <ul className={nav ? `flex flex-col` : `hidden`}>{hamburgerMenuList.map((item)=>(
          <li key={item.id}>{item.name}</li>
        ))}</ul>
      </div>
      <div className="flex items-center mr-14">
        <img className="justify-item-end" src={payTmLogoUrl} alt="payTmLogo" />
      </div>
      {/* desktop menu */}
      <div className="flex">
        <ul className="hidden md:flex justify-between">
          {navItemList.map((item) => (
            <li
              key={item.id}
              className="mx-4 font-semibold hover:pointer"
              onMouseEnter={() => {
                setHoveredItems(item.name);
              }}
              onMouseLeave={() => {
                setHoveredItems(null);
              }}
            >
              {item.name}

              {hoveredItems === item.name && (
                <ul className="absolute bg-white border border-gray-300 p-2 rounded shadow font-light">
                  {item?.subMenu.map((subItem, index) => (
                    <li
                      key={index}
                      className=""
                      onMouseEnter={() => {
                        setHoveredItems(item.name);
                      }}
                      onMouseLeave={() => {
                        setHoveredItems(null);
                      }}
                    >
                      {subItem}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div
        className="flex items-center ml-14 bg-sky-400 hover:bg-blue-950 p-[2px] border-blue-400 rounded-3xl pl-4"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
      >
        <span className="mr-2 text-white ">Sign In</span>
        <img src={isHovered ? signInLogoHovered : signInLogo} alt="user" />
      </div>
    </div>
  );
};

export default NavBar;
