import React from "react";
import NavBar from './components/NavBar';
import HeroSection from "./components/HeroSection";
import KycSection from "./components/KycSection";
import Card from "./components/Card";
import KeepServerAlive from "./components/KeepServerAlive";

function App() {
  return (
    <>
    <NavBar/>
    <KycSection/>
    <HeroSection/>
    <Card/>
    <KeepServerAlive/>
    </>
  );
}

export default App;
