import React from "react";

const HeroSection = () => {
  const logoimageUrl =
    "https://assetscdn1.paytm.com/images/catalog/view/310944/1697527183231.png";
  const logoMobileUrl =
    "https://assetscdn1.paytm.com/images/catalog/view_item/850762/1697527211984.png";
  return (
    <>
      <div className="flex w-full">
        <div className="w-4/6 flex flex-col justify-center ml-72 mt-12">
          <div className="ml-0">
            <img src={logoimageUrl} className="w-[5rem]" alt="" />
          </div>
          <div>
            <p className="font-bold text-6xl mt-9">India's Most-loved</p>
            <p className="font-bold text-6xl mb-4">Payments App</p>
            <p className="text-2xl">
              Recharge & pay bills, book flights & movie tickets,
            </p>
            <p className="text-2xl">
              open a savings account, invest in stocks & mutual
            </p>
            <p className="text-2xl">funds, and do a lot more.</p>
          </div>
          <button className="w-2/12 border border-black rounded-3xl px-3 py-2 mt-12 text-white bg-black hover:text-black hover:bg-white">
            Download Paytm App
          </button>
        </div>

        <div className="w-2/6 flex justify-center items-center">
          <img src={logoMobileUrl} className="mr-3 pt-16  p-14 pr-0" alt="" />
        </div>
      </div>
      
    </>
  );
};

export default HeroSection;

{
  /* <img src={logoimageUrl} className='' alt="" />
   */
}
