import React from "react";
import {ChevronRight} from 'lucide-react'

const Card = () => {
  const cardList = [
    {
      id: 1,
      imageUrl:
        "https://assetscdn1.paytm.com/images/catalog/view_item/733299/1626251017535.png",
      name: "Recharge, Prepaid, Mobile >",
    },
    {
      id: 2,
      imageUrl:
        "https://assetscdn1.paytm.com/images/catalog/view_item/733308/1626251043534.png",
      name: "Pay , Electricity,Bill >",
    },
    {
      id: 3,
      imageUrl:
        "https://assetscdn1.paytm.com/images/catalog/view_item/733311/1626251101045.png",
      name: "Recharge, DTH,Connection >",
    },
    {
      id: 4,
      imageUrl:
        "https://assetscdn1.paytm.com/images/catalog/view_item/733307/1626251127863.png",
      name: "Book, Gas,Cylinder >",
    },
    {
      id: 5,
      imageUrl:
        "https://assetscdn1.paytm.com/images/catalog/view_item/1269194/1672827522093.png",
      name: "Pay,Broadband &,Landline Bill >",
    },
    {
      id: 6,
      imageUrl:
        "https://assetscdn1.paytm.com/images/catalog/view_item/1269198/1672828917034.png",
      name: "Pay, Education,  Fee >",
    },
    {
      id: 7,
      imageUrl:
        "https://assetscdn1.paytm.com/images/catalog/view_item/757701/1626268580682.png",
      name: "All, Payment, Services >",
    },
  ];

  return (
    <div>
      <div className="flex h-96 bg-sky-400 text-white mb-4 flex-col">
        <div className="my-14 ml-48 justify-center items-center">
          <p className="text-3xl ">Recharge & Pay Bills on Paytm.</p>
        </div>
        <div className="h-16 mt-1 mx-48">
          <ul className="flex flex-row justify-between">
            {cardList.map((item) => (
              <li key={item.id} className="flex p-8 flex-col hover:border hover:rounded-lg hover:bg-sky-200 hover:text-black ">
                <img className="w-[5rem]" src={item.imageUrl} alt="" />
                <p className="">
                  {item.name.split(",").map((part) => (
                    <span className="flex flex-col" key={part.trim()}>
                      {part.trim()}
                    </span>
                  ))}
                </p>
              </li>
            ))}
          </ul>
          </div>
      </div>
    </div>
  );
};

export default Card;
