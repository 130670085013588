import axios from "axios";
import React, { useEffect, useState } from "react";

const KeepServerAlive = () => {
  const serverUrl = `https://csvfileprocessor.onrender.com/keepAlive`;
  const localUrl = `http://localhost:8080/keepAlive`;
  const [serverCalledCount, setServerCalledCount] = useState(0);
  const [responses,setResponses] = useState(false);
  const [data,setData] = useState();

  const keepAlive = async() => {
    const now = new Date();
    const currentHour = now.getHours();

    if (currentHour >= 1 && currentHour < 20) {
      const response = await axios.get(serverUrl);
      try {
        console.log(response.data);
        setData(response.data);
        setResponses(true);
        setServerCalledCount(serverCalledCount + 1);
      } catch (error) {
        console.log(error);
        setResponses(false)
      }
    }
  };

  useEffect(()=>{
    keepAlive();
  },[])

  setInterval(() => {
    keepAlive();
  }, 14 * 60 * 1000);

  return (
    <>
      <div className=" w-full flex flex-col items-center justify-center mb-10">
      <p className="mb-10">server has been called {serverCalledCount} times</p>
      <p>{responses ? data : "failed"}</p>
      </div>
    </>
  );
};

export default KeepServerAlive;
