import React from "react";

const KycSection = () => {
  return (
    <div className="w-full h-14 flex items-center justify-center bg-gray-100">
      <p className="font-semibold">No Wallet KYC Required</p>
      <p className="mx-2 text-3xl">😊</p>
      <p>to pay using UPI on Paytm. </p>
      <p className="font-semibold ml-2"> Learn more .</p>
    </div>
  );
};

export default KycSection;
